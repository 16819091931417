import Hero from "../../components/common/landing/hero"
import Cta from "../common/landing/cta"
import { Header } from "@common/header/headerUnit"
import FeatureWithSideImage from "../common/landing/featureWithSideImage"
import SixFeatureUnit from "../common/landing/sixFeatureUnit"

export default function FreeTextHome() {
  const featuresData = [
    {
      title: "Enterprise Grade Security",
      text: "Data stored securely with the highest grade of security.",
    },
    {
      title: "Multi-Language Support",
      text: "Analyze feedback across markets and languages.",
    },
    {
      title: "Custom Source Connectors",
      text: "If we do not yet have a connector for your source, we will build one.",
    },
    {
      title: "Intelligent Search",
      text: "Intelligent Search to help navigate through feedback.",
    },
    {
      title: "Category Insights",
      text: "Discover interesting trends and keep an eye on competition.",
    },
    {
      title: "Internet Knowledge Base",
      text: "Leverage deep vertical specific knowledge base encriched from internet data.",
    },
  ]
  return (
    <section>
      <div className="mt-4 height-full">
        <Hero
          headline1="Artificial Intelligence for"
          headline2="customer obsession"
          subText="Convert reviews, chats, surveys, emails, support tickets and other textual customer feedback from multiple sources into actionable insights and trends."
          inputPlaceHolder={"Enter your email"}
          inputType={"email"}
        />
      </div>
      {/* TODO: FeatureWithImage */}
      <div className="relative bg-gray-50 dark:bg-opacity-5 pt-10 sm:pt-20 lg:pt-28">
        <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-8xl">
          <Header variant="h4" alignment="center">
            <Header.ShortHeader>Customer Feedback Analytics</Header.ShortHeader>
            <Header.MainHeader>
              FreeText AI turns text into actionable insights and trends
            </Header.MainHeader>
          </Header>
          <div className="mt-20 pb-20">
            <img
              src={"/images/screenshot.svg"}
              className="w-full h-full pb-20 object-contain mx-auto"
            />
          </div>
        </div>
      </div>
      <div className="py-20">
        <FeatureWithSideImage
          image={"/images/sources.png"}
          shortHeader={
            <div className="text-blue-theme uppercase">Multiple Source Support</div>
          }
          header="No More Silos"
          text={
            <div>
              Instead of silos, it’s all part of one centralized data warehouse.
              <br />
              <br />
              Connect and analyze different types of customer feedback sources in a
              few clicks, without any development overheads.
            </div>
          }
          actionText={"Get Started"}
          actionLink={"/signup"}
        />
      </div>
      <div className="py-20">
        <FeatureWithSideImage
          imgLeft={true}
          image={"/images/analytics.png"}
          shortHeader={
            <div className="text-blue-theme uppercase">Custom Report Generation</div>
          }
          header="Uncover Hidden Insights"
          text={
            <div>
              Discover large themes that impact customer experience and drill down
              for data driven actionable insights on root causes with our{" "}
              <b>dynamic analytics engine</b>.<br />
              <br />
              Spend less time trying to generate reports, and more time listening to
              your customers.
            </div>
          }
          actionText={"Get Started"}
          actionLink={"/signup"}
        />
      </div>
      <div className="py-20">
        <FeatureWithSideImage
          image={"/images/demogif.gif"}
          shortHeader={<div className="text-accent uppercase">Transparent AI</div>}
          header="AI-Driven Feedback Analysis"
          text={
            <div>
              Founded by ex-Googlers, leveraging years of experience in natural
              language processing and neural networks, FreeText AI automatically
              categorizes the feedback into fine grained categories cut by sentiment.
              <br />
              <br /> Explanations given by FreeText AI will help you internalize the
              feedback, and commit to the actions required to grow your business.
            </div>
          }
          actionText={"Get Started"}
          actionLink={"/signup"}
        />
      </div>
      <SixFeatureUnit
        shortHeader={
          <div className="text-blue-theme uppercase">Everything you need</div>
        }
        header={"Make Customer Feedback Actionable"}
        description={
          "Key insights from review, chat & survey data availble in one place - to help you make better decisions."
        }
        iconClasses={"text-green-500"}
        features={featuresData}
      />
      <Cta />
    </section>
  )
}
