import { Header } from "@common/header/headerUnit"
import SmallFeatureUnit from "./smallFeatureUnit"

export default function SixFeatureUnit({
  shortHeader,
  header,
  description,
  features,
  iconClasses,
}) {
  return (
    <div className="bg-background py-12 md:py-20">
      <div className="max-w-8xl mx-auto py-10 md:py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div>
          <Header alignment="left" variant="h4">
            <Header.ShortHeader>{shortHeader}</Header.ShortHeader>
            <Header.MainHeader>{header}</Header.MainHeader>
            <Header.Description>{description}</Header.Description>
          </Header>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-3 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
            {features?.map((feature, index) => {
              return (
                <SmallFeatureUnit
                  isLeftIcon={true}
                  key={index}
                  simpleIcon={true}
                  icon="tick"
                  iconClasses={iconClasses}
                  title={feature.title}
                  text={feature.text}
                />
              )
            })}
          </dl>
        </div>
      </div>
    </div>
  )
}
