import FreeTextHome from "../components/home/freetext"
import CurrentsHome from "../components/home/currents"
import TheReviewIndex from "../components/home/theReviewIndex"
import { landingLayoutParams } from "../components/layouts/landingLayout"
import { SITE } from "../configs/theme"
import { getFooterLinks } from "../components/tri/triLandingUnit"
import { getLayout } from "../components/layouts/landingLayout"

Home.getLayout = getLayout

// ONLY TRI MODE HAS LINKS IN FOOTE FOR SEO PURPOSES
Home.getLayout = landingLayoutParams(SITE == "tri" ? { getFooterLinks } : null)

export default function Home() {
  return (
    <>
      {SITE == "tri" ? (
        <TheReviewIndex />
      ) : SITE == "currents" ? (
        <CurrentsHome />
      ) : (
        <FreeTextHome />
      )}
    </>
  )
}
