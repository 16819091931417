export default function Video({ src }) {
  return (
    <video
      autoPlay={true}
      muted={true}
      loop={true}
      playsInline={true}
      className=""
    >
      <source
        src={src || "https://storage.googleapis.com/assets_serve/landing.mp4"}
        type="video/mp4"
      />
    </video>
  )
}
